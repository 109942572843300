<template>
  <div class="section13">
    <div class="bg relative" v-if="isMobile">
      <img src="./s13/bg1_m.png" alt="" class="bg-img img1">
      <img src="./s13/bg2_m.png" alt="" class="bg-img" data-aos="fade" data-aos-delay="300">
      <img src="./s13/bg3_m.png" alt="" class="bg-img img2" data-aos="fade" data-aos-delay="500">
    </div>
    <div class="bg relative" v-else>
      <img src="./s13/bg1.png" alt="" class="bg-img">
      <img src="./s13/bg2.png" alt="" class="bg-img" data-aos="fade" data-aos-delay="300">
      <img src="./s13/bg3.png" alt="" class="bg-img" data-aos="fade" data-aos-delay="500">
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bg {
  background-color: #fff;
  background-position: 100% 0%;
  background-size: cover;
  position: relative;
  display: flex;
}

.bg-img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  &:nth-child(1) {
  width: 100%;
  object-fit: initial;
  }
  &:nth-child(3) {
  position: relative;
  height: auto;
  width: 100%;
  max-width:1520px;
  }

}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: auto;
  }

  .img-group {
    width: 800px;

    img {
      width: 180px;
    }
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .bg {
    background-size: cover;
    background-position: center;
    background-image: url('./s9/s9_bg_m.jpg');
  }

  .fullscreen {
    height: auto;
  }

  .img-group {
    width: 650px;

    img {
      width: 140px;
    }
  }

  .m-img {
    position: abso
  }
}

@media screen and (max-width: 767px) {
  .bg {
    background-position: 55%;
    position: relative;
    display: block;
  }
}
</style>

<script>
import { isMobile } from '@/utils'
import slider from '@/mixins/slider.js'
export default {
  name: 'section13',
  mixins: [slider],

  data() {
    return {
      isMobile,
    }
  },

  methods: {},
}
</script>
